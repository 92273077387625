import React, { useEffect, useMemo, useState } from "react";
import { createClient } from "contentful";
import { navigate } from "@reach/router";
import Page from "../templates/page";
const mapRecruiteeJobs = require("../helpers/helpers.ts").mapRecruiteeJobs;

// Make things easier and hardcode locations (for footer and content section w/ locations)
const locations = [
  { name: "Abu Dhabi" },
  { name: "Amsterdam" },
  { name: "Baar" },
  { name: "Bangalore" },
  { name: "Barcelona" },
  { name: "Copenhagen" },
  { name: "Dubai" },
  { name: "Faro" },
  { name: "Gurgaon" },
  { name: "Istanbul" },
  { name: "Lisbon" },
  { name: "London" },
  { name: "Madrid" },
  { name: "Munich" },
  { name: "New Delhi" },
  { name: "Porto" },
  { name: "Zurich" }
];

// Make things easier and hardcode domains (for mapping recruitee jobs)
const domains = [
  {
    name: "Strategy and Execution"
  },
  {
    name: "Big data and analytics"
  },
  {
    name: "Software or hardware"
  },
  {
    name: "Enabling functions"
  },
  {
    name: "Design"
  }
];

// Contentful delivery api sends some types of fields (rich text, long text) with the format
// propName: { propName: string } instead of just propName: string.
// Contentful preview api does not do that... So that has to be adjusted during data mapping.
// This list may change (propably grow) in the future
const duplicateFieldList = [
  "longDescription",
  "description1",
  "description2",
  "text",
  "summary"
];

// Unfortunately, data coming from contentful preview api does not have the same structure as the one coming
// from contentful delivery api (used by gatsby), so it's necessary to map it.
// For other preview pages, that mapping was being done manually, but contain too many possible types of content to do the
// same. This function aims at doing that mapping automatically (and could technically be used in the other preview pages).
// Bc pages can contain a lot of data, there are cases where the recursion of this function makes the max call stack size be exceeded
// (e.g. for the impact page with the big article list). Other changed/new pages may also potentially break
const mapContentfulPreviewApiContent = (arrOrObj, acc) => {
  // arrOrObj is an array
  if (arrOrObj.length) {
    const arr = [];

    arrOrObj.forEach(el => {
      arr.push(
        typeof el === "object" ? mapContentfulPreviewApiContent(el, {}) : el
      );
    });

    return arr;
    // arrOrObj is an object
  } else {
    const acc2 = {};

    Object.entries(arrOrObj).forEach(
      ([key, value]: [key: string, value: any]) => {
        if (key === "fields") {
          mapContentfulPreviewApiContent(value, acc2);
        } else {
          acc[key] =
            value?.nodeType === "document"
              ? { raw: JSON.stringify(value) }
              : typeof value === "object"
              ? mapContentfulPreviewApiContent(value, {})
              : duplicateFieldList.includes(key) && !arrOrObj.sys
              ? { [key]: value }
              : value;
        }
      }
    );

    return { ...acc, ...acc2 };
  }
};

// This page is for content editors to preview a page before publishing it in contentful
export default ({ location }) => {
  const [page, setPage] = useState(null);
  const [jobs, setJobs] = useState(null);
  const pageId = useMemo(
    () => location.search.slice(1, location.search.length),
    []
  );

  useEffect(() => {
    fetch("https://metyisag.recruitee.com/api/offers/")
      .then(res => res.json())
      .then(recruiteeJobs => {
        setJobs(mapRecruiteeJobs(recruiteeJobs, domains));
      });
  }, []);

  useEffect(() => {
    if (process.env.GATSBY_NODE_ENV === "development") {
      const client = createClient({
        space: process.env.GATSBY_CONTENTFUL_SPACE_ID,
        accessToken: process.env.GATSBY_CONTENTFUL_PREVIEW_ACCESS_TOKEN,
        host: process.env.GATSBY_CONTENTFUL_PREVIEW_HOST
      });

      client
        .getEntry(pageId, { include: 4 })
        .then(entry => {
          setPage(entry.fields);
        })
        .catch(err => console.log("An error occurred.", err));
    } else {
      navigate("/404");
    }
  }, []);

  return page && jobs ? (
    <>
      {page.id === "Impact" && (
        <div>
          <h1>Dear content manager</h1>
          <p>
            For technical reasons, impact page's big article list isn't
            displayed in this preview.
          </p>
          <p>
            If an article list is too big the preview page code breaks, so if
            you'd like to simulate having a list, add a smaller one before the
            big one in the impact page's module list (the last element in the
            module list will always be removed).
          </p>
        </div>
      )}
      <Page
        pageContext={{
          ...page,
          content: { jobs, locations },
          metaImage: page.metaImage
            ? mapContentfulPreviewApiContent(page.metaImage, {})
            : undefined,
          modules:
            page.id === "Impact"
              ? mapContentfulPreviewApiContent(
                  page.modules.slice(0, page.modules.length - 1),
                  {}
                )
              : mapContentfulPreviewApiContent(page.modules, {})
        }}
      />
    </>
  ) : (
    <span>Fetching page...</span>
  );
};
